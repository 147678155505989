import { Fragment, forwardRef, useRef } from 'react'
import classNames from 'classnames'
import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'
import Notice from '@/Shared/Notice'
import Radio from '@/Shared/Forms/Radio'
import ScrollableItem from './ScrollableItem'
import Select from '@/Shared/Forms/Select'
import TextArea from '@/Shared/Forms/TextArea'
import TextInput from '@/Shared/Forms/TextInput'
import { usePage } from '@inertiajs/react'

function LandlordVisitQuestionnaire({ data, errors, open, disabled, handleScroll, setData, setRadioValue, onClosed, onSubmit }, scrollRef) {
  const focusRef = useRef()
  const { transaction } = usePage().props
  const formSections = [
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">{questionNumber}. What is the time frame of the rental?</span>
          }
          name="rental_timeframe"
          value={data.rental_timeframe}
          onChange={(value) => setData({ ...data, rental_timeframe: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">
              {questionNumber}. What date will the unit be available to show to prospective tenants?
            </span>
          }
          name="date_available"
          value={data.date_available}
          onChange={(value) => setData({ ...data, date_available: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">
              {questionNumber}. What is the Landlord's preferred Rental Agreement Start Date?
            </span>
          }
          name="preferred_agreement_start_date"
          value={data.preferred_agreement_start_date}
          onChange={(value) => setData({ ...data, preferred_agreement_start_date: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">
              {questionNumber}. What is the Landlord's preferred Rental Agreement End Date?
            </span>
          }
          name="preferred_agreement_end_date"
          value={data.preferred_agreement_end_date}
          onChange={(value) => setData({ ...data, preferred_agreement_end_date: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <Fragment>
          <div className="mb-4 space-y-3">
            <label className={classNames('font-medium', errors.min_rental_term ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. What is the minimum Rental Term (in months) that the Landlord will consider?</span>
            </label>

            <Select
              error={errors.min_rental_term}
              name="min_rental_term"
              options={[
                { value: 'annually', label: '12 Months' },
                { value: 'monthly', label: 'Monthly (tenant at will)' },
                { value: 'other', label: 'Other' },
              ]}
              placeholder="Select One"
              value={data.min_rental_term}
              onChange={(selected) => setData({ ...data, min_rental_term: selected.value })}
              isClearable={false}
            />
          </div>

          {data.min_rental_term !== undefined && data.min_rental_term === 'other' && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <TextInput
                label={
                  <div className="mb-1 text-base normal-case">
                    <div className="font-medium text-gray-700">Description of other minimum Rental Term</div>
                  </div>
                }
                name="min_rental_term_other"
                value={data.min_rental_term_other}
                onChange={(value) => setData({ ...data, min_rental_term_other: value })}
              />
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">
              {questionNumber}. Approximately how many Square Feet of Living Area is being rented?
            </span>
          }
          name="sq_footage"
          value={data.sq_footage}
          onChange={(value) => setData({ ...data, sq_footage: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">
              {questionNumber}. How many levels (including the basement if included in the rent)?
            </span>
          }
          name="levels_included_in_rent"
          value={data.levels_included_in_rent}
          onChange={(value) => setData({ ...data, levels_included_in_rent: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">{questionNumber}. What floor level is the unit on?</span>
          }
          name="unit_floor_level"
          value={data.unit_floor_level}
          onChange={(value) => setData({ ...data, unit_floor_level: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">{questionNumber}. Total Rooms (excluding bathroom)?</span>
          }
          name="total_rooms"
          value={data.total_rooms}
          onChange={(value) => setData({ ...data, total_rooms: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={<span className="text-base font-medium normal-case text-gray-700">{questionNumber}. # of Bedrooms?</span>}
          name="bedrooms"
          value={data.bedrooms}
          onChange={(value) => setData({ ...data, bedrooms: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={<span className="text-base font-medium normal-case text-gray-700">{questionNumber}. # of Bathrooms?</span>}
          name="bathrooms"
          value={data.bathrooms}
          onChange={(value) => setData({ ...data, bathrooms: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={<span className="text-base font-medium normal-case text-gray-700">{questionNumber}. Other Rooms?</span>}
          name="other_rooms"
          value={data.other_rooms}
          onChange={(value) => setData({ ...data, other_rooms: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <Fragment>
          <TextInput
            label={<span className="text-base font-medium normal-case text-gray-700">{questionNumber}. Garage Spaces?</span>}
            name="garage_spaces"
            value={data.garage_spaces}
            onChange={(value) => setData({ ...data, garage_spaces: value })}
          />
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={<span className="text-base font-medium normal-case text-gray-700">{questionNumber}. External Parking Spaces?</span>}
          name="external_parking_spaces"
          value={data.external_parking_spaces}
          onChange={(value) => setData({ ...data, external_parking_spaces: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">
              {questionNumber}. Outdoor buildings or spaces such as shed, storage area, workshop, barn, etc.?
            </span>
          }
          name="outdoor_spaces"
          value={data.outdoor_spaces}
          onChange={(value) => setData({ ...data, outdoor_spaces: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={<span className="text-base font-medium normal-case text-gray-700">{questionNumber}. Outdoor Deck or Patio?</span>}
          name="outdoor_deck_patio"
          value={data.outdoor_deck_patio}
          onChange={(value) => setData({ ...data, outdoor_deck_patio: value })}
        />
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.appliances_included ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. All Appliances to be included?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="appliances_included_yes"
                name="appliances_included"
                label="Yes"
                value={isTrue(data.appliances_included)}
                onChange={() => setRadioValue('appliances_included', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="appliances_included_no"
                name="appliances_included"
                label="No"
                value={isFalse(data.appliances_included)}
                onChange={() => setRadioValue('appliances_included', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.appliances_included }}></div>}
          </div>

          {isTrue(data.appliances_included) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <div className="space-y-3">
                <label className={classNames('font-medium', errors.refrigerator_available ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}a. Refrigerator?</span>
                </label>

                <div className="flex space-x-4">
                  <Radio
                    ref={focusRef}
                    id="refrigerator_available_yes"
                    name="refrigerator_available"
                    label="Yes"
                    value={isTrue(data.refrigerator_available)}
                    onChange={() => setRadioValue('refrigerator_available', true, scrollIntoView)}
                    disabled={disabled}
                  />

                  <Radio
                    id="refrigerator_available_no"
                    name="refrigerator_available"
                    label="No"
                    value={isFalse(data.refrigerator_available)}
                    onChange={() => setRadioValue('refrigerator_available', false, scrollIntoView)}
                    disabled={disabled}
                  />
                </div>

                {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.refrigerator_available }}></div>}
              </div>

              {isTrue(data.refrigerator_available) && (
                <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-200/90 p-4">
                  <div className="space-y-3">
                    <label className={classNames('font-medium', errors.refrigerator_icemaker ? 'text-red-600' : 'text-gray-700')}>
                      <span>I. Icemaker in refrigerator?</span>
                    </label>

                    <div className="flex space-x-4">
                      <Radio
                        ref={focusRef}
                        id="refrigerator_icemaker_yes"
                        name="refrigerator_icemaker"
                        label="Yes"
                        value={isTrue(data.refrigerator_icemaker)}
                        onChange={() => setRadioValue('refrigerator_icemaker', true, scrollIntoView)}
                        disabled={disabled}
                      />

                      <Radio
                        id="refrigerator_icemaker_no"
                        name="refrigerator_icemaker"
                        label="No"
                        value={isFalse(data.refrigerator_icemaker)}
                        onChange={() => setRadioValue('refrigerator_icemaker', false, scrollIntoView)}
                        disabled={disabled}
                      />
                    </div>

                    {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.refrigerator_icemaker }}></div>}
                  </div>
                </div>
              )}

              <div className="space-y-3">
                <label className={classNames('font-medium', errors.stove_range_available ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}b. Stove/Range?</span>
                </label>

                <div className="flex space-x-4">
                  <Radio
                    ref={focusRef}
                    id="stove_range_available_yes"
                    name="stove_range_available"
                    label="Yes"
                    value={isTrue(data.stove_range_available)}
                    onChange={() => setRadioValue('stove_range_available', true, scrollIntoView)}
                    disabled={disabled}
                  />

                  <Radio
                    id="stove_range_available_no"
                    name="stove_range_available"
                    label="No"
                    value={isFalse(data.stove_range_available)}
                    onChange={() => setRadioValue('stove_range_available', false, scrollIntoView)}
                    disabled={disabled}
                  />
                </div>

                {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.stove_range_available }}></div>}
              </div>

              <div className="space-y-3">
                <label className={classNames('font-medium', errors.hood_vent_available ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}c. Hood Vent?</span>
                </label>

                <div className="flex space-x-4">
                  <Radio
                    ref={focusRef}
                    id="hood_vent_available_yes"
                    name="hood_vent_available"
                    label="Yes"
                    value={isTrue(data.hood_vent_available)}
                    onChange={() => setRadioValue('hood_vent_available', true, scrollIntoView)}
                    disabled={disabled}
                  />

                  <Radio
                    id="hood_vent_available_no"
                    name="hood_vent_available"
                    label="No"
                    value={isFalse(data.hood_vent_available)}
                    onChange={() => setRadioValue('hood_vent_available', false, scrollIntoView)}
                    disabled={disabled}
                  />
                </div>

                {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.hood_vent_available }}></div>}
              </div>

              {isTrue(data.hood_vent_available) && (
                <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-200/90 p-4">
                  <div className="space-y-3">
                    <label className={classNames('font-medium', errors.hood_vent_direction ? 'text-red-600' : 'text-gray-700')}>
                      <span>I. Where does the Hood Vent?</span>
                    </label>

                    <div className="flex space-x-4">
                      <Radio
                        ref={focusRef}
                        id="hood_vent_direction_interior"
                        name="hood_vent_direction"
                        label="Interior"
                        value={data.hood_vent_direction === 'interior'}
                        onChange={() => setRadioValue('hood_vent_direction', 'interior', scrollIntoView)}
                        disabled={disabled}
                      />

                      <Radio
                        id="hood_vent_direction_exterior"
                        name="hood_vent_direction"
                        label="Exterior"
                        value={data.hood_vent_direction === 'exterior'}
                        onChange={() => setRadioValue('hood_vent_direction', 'exterior', scrollIntoView)}
                        disabled={disabled}
                      />
                    </div>

                    {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.hood_vent_direction }}></div>}
                  </div>
                </div>
              )}

              <div className="space-y-3">
                <label className={classNames('font-medium', errors.microwave_above_range ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}d. Microwave above range?</span>
                </label>

                <div className="flex space-x-4">
                  <Radio
                    ref={focusRef}
                    id="microwave_above_range_yes"
                    name="microwave_above_range"
                    label="Yes"
                    value={isTrue(data.microwave_above_range)}
                    onChange={() => setRadioValue('microwave_above_range', true, scrollIntoView)}
                    disabled={disabled}
                  />

                  <Radio
                    id="microwave_above_range_no"
                    name="microwave_above_range"
                    label="No"
                    value={isFalse(data.microwave_above_range)}
                    onChange={() => setRadioValue('microwave_above_range', false, scrollIntoView)}
                    disabled={disabled}
                  />
                </div>

                {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.microwave_above_range }}></div>}
              </div>

              {isTrue(data.microwave_above_range) && (
                <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-200/90 p-4">
                  <div className="space-y-3">
                    <label className={classNames('font-medium', errors.microwave_vent_direction ? 'text-red-600' : 'text-gray-700')}>
                      <span>I. Where does the Hood Vent?</span>
                    </label>

                    <div className="flex space-x-4">
                      <Radio
                        ref={focusRef}
                        id="microwave_vent_direction_interior"
                        name="microwave_vent_direction"
                        label="Interior"
                        value={data.microwave_vent_direction === 'interior'}
                        onChange={() => setRadioValue('microwave_vent_direction', 'interior', scrollIntoView)}
                        disabled={disabled}
                      />

                      <Radio
                        id="microwave_vent_direction_exterior"
                        name="microwave_vent_direction"
                        label="Exterior"
                        value={data.microwave_vent_direction === 'exterior'}
                        onChange={() => setRadioValue('microwave_vent_direction', 'exterior', scrollIntoView)}
                        disabled={disabled}
                      />
                    </div>

                    {errors && (
                      <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.microwave_vent_direction }}></div>
                    )}
                  </div>
                </div>
              )}

              {isFalse(data.microwave_above_range) && (
                <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-200/90 p-4">
                  <div className="space-y-3">
                    <label className={classNames('font-medium', errors.microwave_countertop ? 'text-red-600' : 'text-gray-700')}>
                      <span>I. Countertop Microwave?</span>
                    </label>

                    <div className="flex space-x-4">
                      <Radio
                        ref={focusRef}
                        id="microwave_countertop_yes"
                        name="microwave_countertop"
                        label="Yes"
                        value={isTrue(data.microwave_countertop)}
                        onChange={() => setRadioValue('microwave_countertop', true, scrollIntoView)}
                        disabled={disabled}
                      />

                      <Radio
                        id="microwave_countertop_no"
                        name="microwave_countertop"
                        label="No"
                        value={isFalse(data.microwave_countertop)}
                        onChange={() => setRadioValue('microwave_countertop', false, scrollIntoView)}
                        disabled={disabled}
                      />
                    </div>

                    {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.microwave_countertop }}></div>}
                  </div>
                </div>
              )}

              <div className="space-y-3">
                <label className={classNames('font-medium', errors.garbage_disposal ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}e. Garbage Disposal?</span>
                </label>

                <div className="flex space-x-4">
                  <Radio
                    ref={focusRef}
                    id="garbage_disposal_yes"
                    name="garbage_disposal"
                    label="Yes"
                    value={isTrue(data.garbage_disposal)}
                    onChange={() => setRadioValue('garbage_disposal', true, scrollIntoView)}
                    disabled={disabled}
                  />

                  <Radio
                    id="garbage_disposal_no"
                    name="garbage_disposal"
                    label="No"
                    value={isFalse(data.garbage_disposal)}
                    onChange={() => setRadioValue('garbage_disposal', false, scrollIntoView)}
                    disabled={disabled}
                  />
                </div>

                {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.garbage_disposal }}></div>}
              </div>

              <div className="space-y-3">
                <label className={classNames('font-medium', errors.dishwasher ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}f. Dishwasher?</span>
                </label>

                <div className="flex space-x-4">
                  <Radio
                    ref={focusRef}
                    id="dishwasher_yes"
                    name="dishwasher"
                    label="Yes"
                    value={isTrue(data.dishwasher)}
                    onChange={() => setRadioValue('dishwasher', true, scrollIntoView)}
                    disabled={disabled}
                  />

                  <Radio
                    id="dishwasher_no"
                    name="dishwasher"
                    label="No"
                    value={isFalse(data.dishwasher)}
                    onChange={() => setRadioValue('dishwasher', false, scrollIntoView)}
                    disabled={disabled}
                  />
                </div>

                {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.dishwasher }}></div>}
              </div>

              <TextArea
                label={<span className="text-base font-medium normal-case text-gray-800">Other, please describe below</span>}
                name="other_appliance_description"
                value={data.other_appliance_description}
                onChange={(value) => setData({ ...data, other_appliance_description: value })}
              />
            </div>
          )}

          {isFalse(data.appliances_included) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <TextArea
                label={
                  <div className="mb-1 text-base normal-case">
                    <div className="font-medium text-gray-700">Please describe what's included.</div>
                  </div>
                }
                name="appliances_included_description"
                value={data.appliances_included_description}
                onChange={(value) => setData({ ...data, appliances_included_description: value })}
              />
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={<span className="text-base font-medium normal-case text-gray-700">{questionNumber}. Countertops material?</span>}
          name="countertops_material"
          value={data.countertops_material}
          onChange={(value) => setData({ ...data, countertops_material: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">{questionNumber}. Cabinets description (condition)?</span>
          }
          name="cabinets_condition"
          value={data.cabinets_condition}
          onChange={(value) => setData({ ...data, cabinets_condition: value })}
        />
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.electricity_included ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Electricity included?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="electricity_included_yes"
                name="electricity_included"
                label="Yes"
                value={isTrue(data.electricity_included)}
                onChange={() => setRadioValue('electricity_included', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="electricity_included_no"
                name="electricity_included"
                label="No"
                value={isFalse(data.electricity_included)}
                onChange={() => setRadioValue('electricity_included', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.electricity_included }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.heat_included ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Heat included?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="heat_included_yes"
                name="heat_included"
                label="Yes"
                value={isTrue(data.heat_included)}
                onChange={() => setRadioValue('heat_included', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="heat_included_no"
                name="heat_included"
                label="No"
                value={isFalse(data.heat_included)}
                onChange={() => setRadioValue('heat_included', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.heat_included }}></div>}
          </div>

          {isTrue(data.heat_included) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <div className="mb-4 space-y-3">
                <label className={classNames('font-medium', errors.heating_source ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}a. Heating Source</span>
                </label>

                <Select
                  error={errors.heating_source}
                  name="heating_source"
                  options={[
                    { value: 'in-unit furnace', label: 'In unit furnace' },
                    { value: 'central heating', label: 'Central heating system for building' },
                    { value: 'separate unit', label: 'Separate boiler or furnace outside of the unit or in a basement' },
                    { value: 'electric heat pump', label: 'Electric Heat Pump' },
                  ]}
                  placeholder="Select One"
                  value={data.heating_source}
                  onChange={(selected) => setData({ ...data, heating_source: selected.value })}
                  isClearable={false}
                />
              </div>

              <div className="mb-4 space-y-3">
                <label className={classNames('font-medium', errors.heating_type ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}b. Heating Type</span>
                </label>

                <Select
                  error={errors.heating_type}
                  name="heating_type"
                  options={[
                    { value: 'warm air furnace', label: 'Warm Air furnace' },
                    { value: 'radiators', label: 'Radiators' },
                    { value: 'heat pump', label: 'Heat pump' },
                    { value: 'radiant', label: 'Radiant in-floor heating' },
                  ]}
                  placeholder="Select One"
                  value={data.heating_type}
                  onChange={(selected) => setData({ ...data, heating_type: selected.value })}
                  isClearable={false}
                />
              </div>

              <div className="space-y-3">
                <label className={classNames('font-medium', errors.heat_fuel ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}c. Heating Fuel?</span>
                </label>

                <div className="flex space-x-4">
                  <Radio
                    ref={focusRef}
                    id="heat_fuel_yes"
                    name="heat_fuel"
                    label="Yes"
                    value={isTrue(data.heat_fuel)}
                    onChange={() => setRadioValue('heat_fuel', true, scrollIntoView)}
                    disabled={disabled}
                  />

                  <Radio
                    id="heat_fuel_no"
                    name="heat_fuel"
                    label="No"
                    value={isFalse(data.heat_fuel)}
                    onChange={() => setRadioValue('heat_fuel', false, scrollIntoView)}
                    disabled={disabled}
                  />
                </div>

                {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.heat_fuel }}></div>}
              </div>

              {isTrue(data.heat_fuel) && (
                <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-200/90 p-4">
                  <TextInput
                    label={<span className="text-base font-medium normal-case text-gray-700">How is it paid?</span>}
                    name="heat_pay_method"
                    value={data.heat_pay_method}
                    onChange={(value) => setData({ ...data, heat_pay_method: value })}
                  />
                </div>
              )}
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.ac_included ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Air Conditioning?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="ac_included_yes"
                name="ac_included"
                label="Yes"
                value={isTrue(data.ac_included)}
                onChange={() => setRadioValue('ac_included', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="ac_included_no"
                name="ac_included"
                label="No"
                value={isFalse(data.ac_included)}
                onChange={() => setRadioValue('ac_included', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.ac_included }}></div>}
          </div>

          {isTrue(data.ac_included) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <div className="space-y-3">
                <label className={classNames('font-medium', errors.ac_type ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}a. A/C Type?</span>
                </label>

                <div className="flex space-x-4">
                  <Radio
                    ref={focusRef}
                    id="ac_type_window_units"
                    name="ac_type"
                    label="Window Unit(s)"
                    value={data.ac_type === 'window units'}
                    onChange={() => setRadioValue('ac_type', 'window units', scrollIntoView)}
                    disabled={disabled}
                  />

                  <Radio
                    id="ac_type_central"
                    name="ac_type"
                    label="Central Air"
                    value={data.ac_type === 'central'}
                    onChange={() => setRadioValue('ac_type', 'central', scrollIntoView)}
                    disabled={disabled}
                  />
                </div>

                {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.ac_type }}></div>}
              </div>
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.water_sewer_included ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Water/Sewer included?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="water_sewer_included_yes"
                name="water_sewer_included"
                label="Yes"
                value={isTrue(data.water_sewer_included)}
                onChange={() => setRadioValue('water_sewer_included', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="water_sewer_included_no"
                name="water_sewer_included"
                label="No"
                value={isFalse(data.water_sewer_included)}
                onChange={() => setRadioValue('water_sewer_included', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.water_sewer_included }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.trash_included ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Waste Disposal Services included?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="trash_included_yes"
                name="trash_included"
                label="Yes"
                value={isTrue(data.trash_included)}
                onChange={() => setRadioValue('trash_included', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="trash_included_no"
                name="trash_included"
                label="No"
                value={isFalse(data.trash_included)}
                onChange={() => setRadioValue('trash_included', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.trash_included }}></div>}
          </div>

          {isTrue(data.trash_included) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <div className="space-y-3">
                <label className={classNames('font-medium', errors.trash_dumpster_available ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}a. Is there a dumpster at the property?</span>
                </label>

                <div className="flex space-x-4">
                  <Radio
                    ref={focusRef}
                    id="trash_dumpster_available_yes"
                    name="trash_dumpster_available"
                    label="Yes"
                    value={isTrue(data.trash_dumpster_available)}
                    onChange={() => setRadioValue('trash_dumpster_available', true, scrollIntoView)}
                    disabled={disabled}
                  />

                  <Radio
                    id="trash_dumpster_available_no"
                    name="trash_dumpster_available"
                    label="No"
                    value={isFalse(data.trash_dumpster_available)}
                    onChange={() => setRadioValue('trash_dumpster_available', false, scrollIntoView)}
                    disabled={disabled}
                  />
                </div>

                {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.trash_dumpster_available }}></div>}
              </div>

              {isFalse(data.trash_dumpster_available) && (
                <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-200/90 p-4">
                  <div className="space-y-3">
                    <label className={classNames('font-medium', errors.trash_disposal_service ? 'text-red-600' : 'text-gray-700')}>
                      <span>I. Does a disposal service pick up, requiring barrels to be "put out" by the tenant?</span>
                    </label>

                    <div className="flex space-x-4">
                      <Radio
                        ref={focusRef}
                        id="trash_disposal_service_yes"
                        name="trash_disposal_service"
                        label="Yes"
                        value={isTrue(data.trash_disposal_service)}
                        onChange={() => setRadioValue('trash_disposal_service', true, scrollIntoView)}
                        disabled={disabled}
                      />

                      <Radio
                        id="trash_disposal_service_no"
                        name="trash_disposal_service"
                        label="No"
                        value={isFalse(data.trash_disposal_service)}
                        onChange={() => setRadioValue('trash_disposal_service', false, scrollIntoView)}
                        disabled={disabled}
                      />
                    </div>

                    {errors && (
                      <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.trash_disposal_service }}></div>
                    )}
                  </div>

                  {isTrue(data.trash_disposal_service) && (
                    <TextInput
                      label={
                        <span className="text-base font-medium normal-case text-gray-700">
                          II. What day(s) is trash and recycling picked up
                        </span>
                      }
                      name="trash_disposal_service_pickup_day"
                      value={data.trash_disposal_service_pickup_day}
                      onChange={(value) => setData({ ...data, trash_disposal_service_pickup_day: value })}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.grounds_upkeep_included ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Snow Removal and ground upkeep included?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="grounds_upkeep_included_yes"
                name="grounds_upkeep_included"
                label="Yes"
                value={isTrue(data.grounds_upkeep_included)}
                onChange={() => setRadioValue('grounds_upkeep_included', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="grounds_upkeep_included_no"
                name="grounds_upkeep_included"
                label="No"
                value={isFalse(data.grounds_upkeep_included)}
                onChange={() => setRadioValue('grounds_upkeep_included', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.grounds_upkeep_included }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.parking_included ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Parking included?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="parking_included_yes"
                name="parking_included"
                label="Yes"
                value={isTrue(data.parking_included)}
                onChange={() => setRadioValue('parking_included', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="parking_included_no"
                name="parking_included"
                label="No"
                value={isFalse(data.parking_included)}
                onChange={() => setRadioValue('parking_included', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.parking_included }}></div>}
          </div>

          {isFalse(data.parking_included) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <TextInput
                label={
                  <span className="text-base font-medium normal-case text-gray-700">
                    {questionNumber}a. If parking is available for a fee, what is the amount and payment schedule?
                  </span>
                }
                name="parking_payment_info"
                value={data.parking_payment_info}
                onChange={(value) => setData({ ...data, parking_payment_info: value })}
              />

              <TextInput
                label={
                  <span className="text-base font-medium normal-case text-gray-700">{questionNumber}b. Other parking information.</span>
                }
                name="parking_other"
                value={data.parking_other}
                onChange={(value) => setData({ ...data, parking_other: value })}
              />
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <Fragment>
          <div className="mb-4 space-y-3">
            <label className={classNames('font-medium', errors.laundry_services_available ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Available Laundry Services</span>
            </label>

            <Select
              error={errors.laundry_services_available}
              name="laundry_services_available"
              options={[
                { value: 'in-unit', label: 'In-Unit Laundry, including a washer and dryer' },
                { value: 'common area', label: 'Common laundry area available for all tenants to use' },
                { value: 'none', label: 'No laundry machines at the property' },
              ]}
              placeholder="Select One"
              value={data.laundry_services_available}
              onChange={(selected) => setData({ ...data, laundry_services_available: selected.value })}
              isClearable={false}
            />
          </div>
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">{questionNumber}. List any other services included</span>
          }
          name="other_included_services"
          value={data.other_included_services}
          onChange={(value) => setData({ ...data, other_included_services: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <Fragment>
          <TextInput
            label={
              <span className="text-base font-medium normal-case text-gray-700">
                {questionNumber}. If building has a garage, shed or basement, can it be used for personal use, and what restrictions (if
                any)?
              </span>
            }
            name="garage_shed_basement_for_personal_use"
            value={data.garage_shed_basement_for_personal_use}
            onChange={(value) => setData({ ...data, garage_shed_basement_for_personal_use: value })}
          />
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <Fragment>
          <TextInput
            label={
              <span className="text-base font-medium normal-case text-gray-700">
                {questionNumber}. How old are the Smoke Detectors and Carbon Monoxide Detectors?
              </span>
            }
            name="detectors_ages"
            value={data.detectors_ages}
            onChange={(value) => setData({ ...data, detectors_ages: value })}
          />

          <Notice
            heading="All detectors have dates on the bottom or sides of the detectors."
            subHeading={
              <p>
                All homes are required by STATE LAW to have <b>smoke detectors and carbon monoxide (CO)</b> detectors that are not only
                working, but more specifically, are in compliance with current and updated state and municipal laws. Just because your
                detectors are working or passed an inspection DOES NOT mean they meet current state fire code laws that tend to change
                often. We recommend that you <u>hire an electrician</u> to inspect and install new smoke detectors and carbon monoxide (CO)
                detectors in anticipation of your sale. If you prefer to replace your detectors on your own, then we ask that you contact
                your municipal fire department and have them assist you with the type and placement of proper detectors to comply with the
                type of building/property that you are selling. Smoke detectors and carbon monoxide detectors may be required to be
                reinstalled in different locations, additional locations, hard-wired and updated with photoelectric technology depending on
                the type of property, the age of the property, the size of the property. Carbon monoxide detectors CO) and combination smoke
                detectors/carbon monoxide detectors are required to be replaced more often than smoke detectors in most states. Contact your
                municipal fire department for more details. Certification of detectors by your municipal fire department is required by law
                and without it, your property may not close on time. It's best to consider having an licensed electrician inspect, upgrade
                and replace your existing smoke detectors early in the sale process.
              </p>
            }
          />
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">
              {questionNumber}. Describe improvements to the unit to help with marketing.
            </span>
          }
          name="improvements"
          value={data.improvements}
          onChange={(value) => setData({ ...data, improvements: value })}
        />
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.mail_delivery_type ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. How is the mail delivered?</span>
            </label>

            <Select
              error={errors.mail_delivery_type}
              name="mail_delivery_type"
              options={[
                { value: 'door', label: "Delivered to the tenant's door" },
                { value: 'mailbox', label: 'Mailbox' },
                { value: 'centralized', label: 'Centralized Location' },
              ]}
              placeholder="Select One"
              value={data.mail_delivery_type}
              onChange={(selected) => setData({ ...data, mail_delivery_type: selected.value })}
              isClearable={false}
            />

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.mail_delivery_type }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">
              {questionNumber}. What is the Pet Policy including any restrictions of type, weight, breed?
            </span>
          }
          name="pet_policy"
          value={data.pet_policy}
          onChange={(value) => setData({ ...data, pet_policy: value })}
        />
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.surveillance ? 'text-red-600' : 'text-gray-700')}>
              <span>
                {questionNumber}. Are there any video or audio surveillance devices in or attached to the building?
                <div className="font-normal">
                  This may include devices such as Ring or Nest doorbells, security cameras, "nanny" cameras or any other listening, video
                  or recording device? If you do have these devices, you may be subject to state laws requiring disclosure of video or audio
                  recording and surveillance devices. As such, we can review guidelines and "best practices" that we recommend as
                  professional Realtors as it pertains to use and disclosure of surveillance devices.
                </div>
              </span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="surveillance_yes"
                name="surveillance"
                label="Yes"
                value={isTrue(data.surveillance)}
                onChange={() => setRadioValue('surveillance', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="surveillance_no"
                name="surveillance"
                label="No"
                value={isFalse(data.surveillance)}
                onChange={() => setRadioValue('surveillance', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.surveillance }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.landlord_screening ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Will prospective tenant be referred to the Landlord for application and screening?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="landlord_screening_yes"
                name="landlord_screening"
                label="Yes"
                value={isTrue(data.landlord_screening)}
                onChange={() => setRadioValue('landlord_screening', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="landlord_screening_no"
                name="landlord_screening"
                label="No"
                value={isFalse(data.landlord_screening)}
                onChange={() => setRadioValue('landlord_screening', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.landlord_screening }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.blre_screening ? 'text-red-600' : 'text-gray-700')}>
              <span>
                {questionNumber}. Will Better Living Real Estate provide the tenant with a REALTOR Rental Application and/or screening by
                rental software?
              </span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="blre_screening_yes"
                name="blre_screening"
                label="Yes"
                value={isTrue(data.blre_screening)}
                onChange={() => setRadioValue('blre_screening', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="blre_screening_no"
                name="blre_screening"
                label="No"
                value={isFalse(data.blre_screening)}
                onChange={() => setRadioValue('blre_screening', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.blre_screening }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.background_checks ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Who will conduct background checks?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="background_checks_landlord"
                name="background_checks"
                label="Landlord"
                value={data.background_checks === 'landlord'}
                onChange={() => setRadioValue('background_checks', 'landlord', scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="background_checks_blre"
                name="background_checks"
                label="Better Living Real Estate"
                value={data.background_checks === 'blre'}
                onChange={() => setRadioValue('background_checks', 'blre', scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.background_checks }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.credit_score ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Who will provide the credit score?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="credit_score_landlord"
                name="credit_score"
                label="Landlord"
                value={data.credit_score === 'landlord'}
                onChange={() => setRadioValue('credit_score', 'landlord', scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="credit_score_blre"
                name="credit_score"
                label="Better Living Real Estate"
                value={data.credit_score === 'blre'}
                onChange={() => setRadioValue('credit_score', 'blre', scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.credit_score }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.former_references_required ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Are former Landlord references required?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="former_references_required_yes"
                name="former_references_required"
                label="Yes"
                value={isTrue(data.former_references_required)}
                onChange={() => setRadioValue('former_references_required', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="former_references_required_no"
                name="former_references_required"
                label="No"
                value={isFalse(data.former_references_required)}
                onChange={() => setRadioValue('former_references_required', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.former_references_required }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.income_verification_required ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Are income verification documents (i.e. W-9, tax returns, etc.) required by the Landlord?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="income_verification_required_yes"
                name="income_verification_required"
                label="Yes"
                value={isTrue(data.income_verification_required)}
                onChange={() => setRadioValue('income_verification_required', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="income_verification_required_no"
                name="income_verification_required"
                label="No"
                value={isFalse(data.income_verification_required)}
                onChange={() => setRadioValue('income_verification_required', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.income_verification_required }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.new_key_cost ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. New Key cost?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="new_key_cost_yes"
                name="new_key_cost"
                label="Yes"
                value={isTrue(data.new_key_cost)}
                onChange={() => setRadioValue('new_key_cost', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="new_key_cost_no"
                name="new_key_cost"
                label="No"
                value={isFalse(data.new_key_cost)}
                onChange={() => setRadioValue('new_key_cost', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.new_key_cost }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.move_in_fee ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Move-in fee?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="move_in_fee_yes"
                name="move_in_fee"
                label="Yes"
                value={isTrue(data.move_in_fee)}
                onChange={() => setRadioValue('move_in_fee', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="move_in_fee_no"
                name="move_in_fee"
                label="No"
                value={isFalse(data.move_in_fee)}
                onChange={() => setRadioValue('move_in_fee', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.move_in_fee }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.security_deposit ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Security Deposit?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="security_deposit_yes"
                name="security_deposit"
                label="Yes"
                value={isTrue(data.security_deposit)}
                onChange={() => setRadioValue('security_deposit', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="security_deposit_no"
                name="security_deposit"
                label="No"
                value={isFalse(data.security_deposit)}
                onChange={() => setRadioValue('security_deposit', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.security_deposit }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.first_mont_rent_required ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. First Month Rent?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="first_mont_rent_required_yes"
                name="first_mont_rent_required"
                label="Yes"
                value={isTrue(data.first_mont_rent_required)}
                onChange={() => setRadioValue('first_mont_rent_required', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="first_mont_rent_required_no"
                name="first_mont_rent_required"
                label="No"
                value={isFalse(data.first_mont_rent_required)}
                onChange={() => setRadioValue('first_mont_rent_required', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.first_mont_rent_required }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.last_mont_rent_required ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Last Month Rent?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="last_mont_rent_required_yes"
                name="last_mont_rent_required"
                label="Yes"
                value={isTrue(data.last_mont_rent_required)}
                onChange={() => setRadioValue('last_mont_rent_required', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="last_mont_rent_required_no"
                name="last_mont_rent_required"
                label="No"
                value={isFalse(data.last_mont_rent_required)}
                onChange={() => setRadioValue('last_mont_rent_required', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.last_mont_rent_required }}></div>}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.cleaning_cost_required ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Cleaning Cost?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="cleaning_cost_required_yes"
                name="cleaning_cost_required"
                label="Yes"
                value={isTrue(data.cleaning_cost_required)}
                onChange={() => setRadioValue('cleaning_cost_required', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="cleaning_cost_required_no"
                name="cleaning_cost_required"
                label="No"
                value={isFalse(data.cleaning_cost_required)}
                onChange={() => setRadioValue('cleaning_cost_required', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.cleaning_cost_required }}></div>}
          </div>
        </Fragment>
      ),
    },
  ]

  const isTrue = (value) => {
    if (value == undefined) {
      return false
    }

    return value === true || value === 1 || value === '1'
  }

  const isFalse = (value) => {
    if (value == undefined) {
      return false
    }

    return value === false || value === 0 || value === '0'
  }

  return (
    <Dialog
      ref={scrollRef}
      cancelText="Close"
      focusRef={focusRef}
      footerActions={
        transaction.editable && (
          <Button form="transactionForm" type="submit" theme="solid">
            Save Changes
          </Button>
        )
      }
      position="top"
      show={open}
      size={'max-w-4xl'}
      title={data.name}
      onClosed={onClosed}
    >
      {data && (
        <form id="transactionForm" className="space-y-4" onSubmit={onSubmit}>
          {formSections.map((item, index) => {
            if (item.type === 'scrollable') {
              return (
                <ScrollableItem key={index} id={`question-${index}`} onScroll={(ref) => handleScroll(ref)}>
                  {({ scrollIntoView }) => item.render(scrollIntoView, index + 1)}
                </ScrollableItem>
              )
            } else if (item.type === 'section') {
              return (
                <div key={index} id={`question-${index}`}>
                  {item.render(index + 1)}
                </div>
              )
            }
            return null
          })}

          <div>
            <TextArea
              label={
                <div className="mb-1 text-base normal-case">
                  <div className="font-medium text-gray-700">Other notes or information</div>
                </div>
              }
              name="field_notes"
              value={data.field_notes}
              onChange={(value) => setData({ ...data, field_notes: value })}
            />
          </div>
        </form>
      )}
    </Dialog>
  )
}

export default forwardRef(LandlordVisitQuestionnaire)
