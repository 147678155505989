import { Fragment, forwardRef, useRef } from 'react'
import classNames from 'classnames'
import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'
import Notice from '@/Shared/Notice'
import Radio from '@/Shared/Forms/Radio'
import ScrollableItem from './ScrollableItem'
import Select from '@/Shared/Forms/Select'
import TextArea from '@/Shared/Forms/TextArea'
import TextInput from '@/Shared/Forms/TextInput'
import { usePage } from '@inertiajs/react'

function SellerVisitQuestionnaire({ data, errors, open, disabled, handleScroll, setData, setRadioValue, onClosed, onSubmit }, scrollRef) {
  const focusRef = useRef()
  const { transaction } = usePage().props
  const formSections = [
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.association ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Is the property part of an Association (HOA or Condominium Association)?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                ref={focusRef}
                id="association_yes"
                name="association"
                label="Yes"
                value={isTrue(data.association)}
                onChange={() => setRadioValue('association', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="association_no"
                name="association"
                label="No"
                value={isFalse(data.association)}
                onChange={() => setRadioValue('association', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.association }}></div>}
          </div>

          {isTrue(data.association) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <Notice
                heading="Request that the seller obtain and provide a copy of the Association Documents for disclosure to buyers and attachment to
                the MLS listing."
              />

              <TextInput
                label={
                  <span className="text-base font-medium normal-case text-gray-800">
                    {questionNumber}a. What is the Association fee and what is covered by the Association fee?
                  </span>
                }
                name="association_fee"
                value={data.association_fee}
                onChange={(value) => setData({ ...data, association_fee: value })}
              />
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.road_public ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Is the road Public or Private?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="road_public"
                name="road_public"
                label="Public"
                value={isTrue(data.road_public)}
                onChange={() => setRadioValue('road_public', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="road_private"
                name="road_public"
                label="Private"
                value={isFalse(data.road_public)}
                onChange={() => setRadioValue('road_public', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.road_public }}></div>}
          </div>

          {data.road_public != undefined && isFalse(data.road_public) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <TextInput
                label={
                  <span className="text-base font-medium normal-case text-gray-800">
                    {questionNumber}a. Who maintains and plows the road?
                  </span>
                }
                name="road_maintenance"
                value={data.road_maintenance}
                onChange={(value) => setData({ ...data, road_maintenance: value })}
              />
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.street_number_visible ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Is there a visible street number attached to the building?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="street_number_visible_yes"
                name="street_number_visible"
                label="Yes"
                value={isTrue(data.street_number_visible)}
                onChange={() => setRadioValue('street_number_visible', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="street_number_visible_no"
                name="street_number_visible"
                label="No"
                value={isFalse(data.street_number_visible)}
                onChange={() => setRadioValue('street_number_visible', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.street_number_visible }}></div>}
          </div>

          {data.street_number_visible != undefined && isFalse(data.street_number_visible) && (
            <Notice
              heading="Confirm the property has a conspicuously visible street “number” affixed on the residence, property or building such that it is visible from the street and/or compliant with the municipal fire department's requirements for posted street address which may be required for the fire department's certification of smoke detectors and carbon monoxide detectors."
              subHeading="If you have any questions about the visibility of street address “number,” please contact the municipal fire department where the property is located for specific instructions and information."
            />
          )}
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">
              {questionNumber}. How does the seller remove trash and recycling? Is there a charge for municipal trash service?  If “yes” how
              much?
            </span>
          }
          name="waste_management"
          value={data.waste_management}
          onChange={(value) => setData({ ...data, waste_management: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">
              {questionNumber}. What is the Style of the Home or Building?
            </span>
          }
          name="property_style"
          value={data.property_style}
          onChange={(value) => setData({ ...data, property_style: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">
              {questionNumber}. What type of siding material(s) is on the building?
            </span>
          }
          name="siding_material"
          value={data.siding_material}
          onChange={(value) => setData({ ...data, siding_material: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <span className="text-base font-medium normal-case text-gray-700">
              {questionNumber}.  What type of roof material is on the roof? How old is the roof?
            </span>
          }
          name="roof_material"
          value={data.roof_material}
          onChange={(value) => setData({ ...data, roof_material: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <div className="mb-1 text-base normal-case">
              <div className="font-medium text-gray-700">
                {questionNumber}.  What brand & type of windows are in the property and how old are the windows?
              </div>
              <div>i.e. Double Hung, Single Hung, Casement, Awning, Basement Hopper, Slider Windows, Bay, Bow</div>
            </div>
          }
          name="windows_type"
          value={data.windows_type}
          onChange={(value) => setData({ ...data, windows_type: value })}
        />
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.basement_water ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Has the basement ever had seepage or water?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="basement_water_yes"
                name="basement_water"
                label="Yes"
                value={isTrue(data.basement_water)}
                onChange={() => setData({ ...data, basement_water: true, scrollIntoView })}
                disabled={disabled}
              />

              <Radio
                id="basement_water_no"
                name="basement_water"
                label="No"
                value={isFalse(data.basement_water)}
                onChange={() => setData({ ...data, basement_water: false, scrollIntoView })}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.basement_water }}></div>}
          </div>

          {isTrue(data.basement_water) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <TextArea
                label={<span className="text-base font-medium normal-case text-gray-800">Please describe below</span>}
                name="basement_water_description"
                value={data.basement_water_description}
                onChange={(value) => setData({ ...data, basement_water_description: value })}
              />
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.sump ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Is there a sump pump? </span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="sump_yes"
                name="sump"
                label="Yes"
                value={isTrue(data.sump)}
                onChange={() => setRadioValue('sump', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="sump_no"
                name="sump"
                label="No"
                value={isFalse(data.sump)}
                onChange={() => setRadioValue('sump', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.sump }}></div>}
          </div>

          {isTrue(data.sump) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <label className={classNames('font-medium', errors.sump_working ? 'text-red-600' : 'text-gray-700')}>
                <span>{questionNumber}a. Is it operational?</span>
              </label>

              <div className="flex space-x-4">
                <Radio
                  id="sump_working_yes"
                  name="sump_working"
                  label="Yes"
                  value={isTrue(data.sump_working)}
                  onChange={() => setData({ ...data, sump_working: true })}
                  disabled={disabled}
                />

                <Radio
                  id="sump_working_no"
                  name="sump_working"
                  label="No"
                  value={isFalse(data.sump_working)}
                  onChange={() => setData({ ...data, sump_working: false })}
                  disabled={disabled}
                />
              </div>
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <div className="space-y-3">
          <label className={classNames('font-medium', errors.radon ? 'text-red-600' : 'text-gray-700')}>
            <span>{questionNumber}. Is there a radon mitigation system located in the building?</span>
          </label>

          <div className="flex space-x-4">
            <Radio
              id="radon_yes"
              name="radon"
              label="Yes"
              value={isTrue(data.radon)}
              onChange={() => setData({ ...data, radon: true })}
              disabled={disabled}
            />

            <Radio
              id="radon_no"
              name="radon"
              label="No"
              value={isFalse(data.radon)}
              onChange={() => setData({ ...data, radon: false })}
              disabled={disabled}
            />
          </div>

          {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.radon }}></div>}
        </div>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <div className="mb-1 text-base normal-case">
              <div className="font-medium text-gray-700">{questionNumber}.  What type of electrical service and how many Amps?</div>
              <div>i.e. Circuit Breakers, Fuses, Knob & Tube</div>
            </div>
          }
          name="electrical"
          value={data.electrical}
          onChange={(value) => setData({ ...data, electrical: value })}
        />
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.standby_generator ? 'text-red-600' : 'text-gray-700')}>
              <span>
                {questionNumber}. Is there an automatic standby generator or portable generator connection at the electric panel in case of
                power outages?
              </span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="standby_generator_yes"
                name="standby_generator"
                label="Yes"
                value={isTrue(data.standby_generator)}
                onChange={() => setRadioValue('standby_generator', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="standby_generator_no"
                name="standby_generator"
                label="No"
                value={isFalse(data.standby_generator)}
                onChange={() => setRadioValue('standby_generator', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.standby_generator }}></div>}
          </div>

          {isTrue(data.standby_generator) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <TextArea
                label={<span className="text-base font-medium normal-case text-gray-800">Please describe below</span>}
                name="standby_generator_description"
                value={data.standby_generator_description}
                onChange={(value) => setData({ ...data, standby_generator_description: value })}
              />
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.solar ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Does the property have a Solar panel or roof system installed? </span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="solar_yes"
                name="solar"
                label="Yes"
                value={isTrue(data.solar)}
                onChange={() => setRadioValue('solar', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="solar_not"
                name="solar"
                label="No"
                value={isFalse(data.solar)}
                onChange={() => setRadioValue('solar', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.solar }}></div>}
          </div>

          {isTrue(data.solar) && (
            <div className="space-y-3">
              <Notice
                heading="Ask the seller to provide you a copy of the solar agreement, which you will want to attach to the MLS listing, or provide to the Buyer/Buyer Agent for review prior to signing the purchase and sale."
                subHeading="For more information about solar systems and how they affect the value of a property, search the keyword “solar” in the Help & SUPPORT Forum."
              />

              <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
                <label className={classNames('font-medium', errors.solar_owned ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}a. Is it owned or leased?</span>
                </label>

                <div className="flex space-x-4">
                  <Radio
                    id="solar_owned_yes"
                    name="solar_owned"
                    label="Owned"
                    value={isTrue(data.solar_owned)}
                    onChange={() => setData({ ...data, solar_owned: true })}
                    disabled={disabled}
                  />

                  <Radio
                    id="solar_owned_no"
                    name="solar_owned"
                    label="Leased"
                    value={isFalse(data.solar_owned)}
                    onChange={() => setData({ ...data, solar_owned: false })}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <div className="mb-1 text-base normal-case">
              <div className="font-medium text-gray-700">
                {questionNumber}.  What type of Heating System is in the property?  What type of fuel?
              </div>
              <div>i.e. Natural gas, propane, electric heat pump</div>
            </div>
          }
          name="heating"
          value={data.heating}
          onChange={(value) => setData({ ...data, heating: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <div className="mb-1 text-base normal-case">
              <div className="font-medium text-gray-700">
                {questionNumber}.  How many Thermostats are in the property, and how many heating zones?
              </div>
            </div>
          }
          name="thermostats_heating_zones"
          value={data.thermostats_heating_zones}
          onChange={(value) => setData({ ...data, thermostats_heating_zones: value })}
        />
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.air_conditioning ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Does the property have Air Conditioning?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="air_conditioning_yes"
                name="air_conditioning"
                label="Yes"
                value={isTrue(data.air_conditioning)}
                onChange={() => setRadioValue('air_conditioning', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="air_conditioning_no"
                name="air_conditioning"
                label="No"
                value={isFalse(data.air_conditioning)}
                onChange={() => setRadioValue('air_conditioning', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.air_conditioning }}></div>}
          </div>

          {isTrue(data.air_conditioning) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <div className="space-y-3">
                <label className={classNames('font-medium', errors.air_conditioning_all_floors ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}a. On all floors?</span>
                </label>

                <div className="flex space-x-4">
                  <Radio
                    id="air_conditioning_all_floors_yes"
                    name="air_conditioning_all_floors"
                    label="Yes"
                    value={isTrue(data.air_conditioning_all_floors)}
                    onChange={() => setRadioValue('air_conditioning_all_floors', true, scrollIntoView)}
                    disabled={disabled}
                  />

                  <Radio
                    id="air_conditioning_all_floors_no"
                    name="air_conditioning_all_floors"
                    label="No"
                    value={isFalse(data.air_conditioning_all_floors)}
                    onChange={() => setRadioValue('air_conditioning_all_floors', false, scrollIntoView)}
                    disabled={disabled}
                  />
                </div>
              </div>

              <TextInput
                label={<span className="text-base font-medium normal-case text-gray-800">{questionNumber}b. What type of system(s)?</span>}
                name="air_conditioning_type"
                value={data.air_conditioning_type}
                onChange={(value) => setData({ ...data, air_conditioning_type: value })}
              />

              <TextInput
                label={
                  <div className="mb-1 text-base normal-case">
                    <div className="font-medium text-gray-700">
                      {questionNumber}c.  How many Cooling Zones are in the property? How many Thermostats service the air conditioning?
                    </div>
                  </div>
                }
                name="cooling_zones"
                value={data.cooling_zones}
                onChange={(value) => setData({ ...data, cooling_zones: value })}
              />
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="mb-4 space-y-3">
            <label className={classNames('font-medium', errors.hot_water_tank ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. What is the Domestic Hot Water heated with?</span>
            </label>

            <Select
              error={errors.hot_water_tank}
              name="hot_water_tank"
              options={[
                { value: 'tank', label: 'Storage (Tank) Water Heater' },
                { value: 'tankless', label: '“Tankless” (On-Demand) Water Heater' },
                { value: 'heat pump', label: 'Heat Pump Water Heater' },
                { value: 'solar', label: 'Solar Water Heater' },
                { value: 'hybrid', label: 'Hybrid Water Heater' },
                { value: 'indirect', label: 'Indirect Water Heater' },
              ]}
              placeholder="Select One"
              value={
                data.hot_water_tank !== undefined &&
                (data.hot_water_tank.toString() === 'true'
                  ? 'tank'
                  : data.hot_water_tank.toString() === 'false'
                    ? 'tankless'
                    : data.hot_water_tank)
              }
              onChange={(selected) => setData({ ...data, hot_water_tank: selected.value })}
              isClearable={false}
            />
          </div>

          {data.hot_water_tank !== undefined && (data.hot_water_tank.toString() === 'true' || data.hot_water_tank === 'tank') && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <TextInput
                label={
                  <div className="mb-1 text-base normal-case">
                    <div className="font-medium text-gray-700">{questionNumber}a.  What type of fuel?</div>
                    <div>i.e. Natural gas, propane, electric</div>
                  </div>
                }
                name="hot_water_fuel"
                value={data.hot_water_fuel}
                onChange={(value) => setData({ ...data, hot_water_fuel: value })}
              />

              <div className="space-y-3">
                <label className={classNames('font-medium', errors.hot_water_tank_rented ? 'text-red-600' : 'text-gray-700')}>
                  <span>{questionNumber}b. Is the hot water tenk rented from the utility or owned by the owner?</span>
                </label>

                <div className="flex space-x-4">
                  <Radio
                    id="hot_water_tank_rented_yes"
                    name="hot_water_tank_rented"
                    label="Rented"
                    value={isTrue(data.hot_water_tank_rented)}
                    onChange={() => setRadioValue('hot_water_tank_rented', true, scrollIntoView)}
                    disabled={disabled}
                  />

                  <Radio
                    id="hot_water_tank_rented_no"
                    name="hot_water_tank_rented"
                    label="Owned"
                    value={isFalse(data.hot_water_tank_rented)}
                    onChange={() => setRadioValue('hot_water_tank_rented', false, scrollIntoView)}
                    disabled={disabled}
                  />
                </div>

                {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.hot_water_tank_rented }}></div>}
              </div>

              {isTrue(data.hot_water_tank_rented) && (
                <Notice
                  heading="Obtain a copy of the rental agreement and details from the seller so that you and disclose it in MLS and provide details/disclosure to a buyer prior to signing a purchase and sale agreement."
                  subHeading="Water heater ownership should be properly documented in a Seller Disclosure form."
                />
              )}
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.water_well ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Is the domestic (drinking) water municipal or well?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="water_well_no"
                name="water_well"
                label="Municipal"
                value={isFalse(data.water_well)}
                onChange={() => setRadioValue('water_well', false, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="water_well_yes"
                name="water_well"
                label="Well"
                value={isTrue(data.water_well)}
                onChange={() => setRadioValue('water_well', true, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.water_well }}></div>}
          </div>

          {isTrue(data.water_well) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <TextInput
                label={
                  <span className="text-base font-medium normal-case text-gray-800">{questionNumber}a. Where is the wellhead located?</span>
                }
                name="water_wellhead_location"
                value={data.water_wellhead_location}
                onChange={(value) => setData({ ...data, water_wellhead_location: value })}
              />

              <Notice
                heading="Some municipalities require that the well water is tested by the seller, and the test results meet the standards established by the municipal Board of Health Department prior to the sale of the home and closing.  Contact the local health department for more information on local regulations regarding the transfer of well ownership."
                subHeading="Have the Seller obtain a well water test (if necessary) and attach the results to your MLS listing."
              />
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.sewer_public ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Is there Public (municipal) sewer or a Private “onsite” sewer?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="sewer_public_yes"
                name="sewer_public"
                label="Public Municipal Sewer"
                value={isTrue(data.sewer_public)}
                onChange={() => setRadioValue('sewer_public', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="sewer_public_no"
                name="sewer_public"
                label="Private “onsite” Sewer"
                value={isFalse(data.sewer_public)}
                onChange={() => setRadioValue('sewer_public', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.sewer_public }}></div>}
          </div>

          {data.sewer_public != undefined &&
            (isTrue(data.sewer_public) ? (
              <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
                <div className="space-y-3">
                  <label className={classNames('font-medium', errors.sewer_public_assessment ? 'text-red-600' : 'text-gray-700')}>
                    <span>{questionNumber}a. Is there an assessment?</span>
                  </label>

                  <div className="flex space-x-4">
                    <Radio
                      id="sewer_public_assessment_yes"
                      name="sewer_public_assessment"
                      label="Yes"
                      value={isTrue(data.sewer_public_assessment)}
                      onChange={() => setRadioValue('sewer_public_assessment', true, scrollIntoView)}
                      disabled={disabled}
                    />

                    <Radio
                      id="sewer_public_assessment_no"
                      name="sewer_public_assessment"
                      label="No"
                      value={isFalse(data.sewer_public_assessment)}
                      onChange={() => setRadioValue('sewer_public_assessment', false, scrollIntoView)}
                      disabled={disabled}
                    />
                  </div>

                  {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.sewer_public_assessment }}></div>}

                  {isTrue(data.sewer_public_assessment) && (
                    <div className="space-y-3">
                      <TextInput
                        label={
                          <div className="mb-1 text-base normal-case">
                            <div className="font-medium text-gray-700">{questionNumber}b. How much is the assessment?</div>
                          </div>
                        }
                        name="sewer_public_assessment_amount"
                        value={data.sewer_public_assessment_amount}
                        onChange={(value) => setData({ ...data, sewer_public_assessment_amount: value })}
                      />

                      <div className="space-y-3">
                        <label className={classNames('font-medium', errors.sewer_public_assessment ? 'text-red-600' : 'text-gray-700')}>
                          <span>{questionNumber}c. Will it be paid at the closing by the seller?</span>
                        </label>

                        <div className="flex space-x-4">
                          <Radio
                            id="sewer_public_assessment_paid_yes"
                            name="sewer_public_assessment_paid"
                            label="Yes"
                            value={isTrue(data.sewer_public_assessment_paid)}
                            onChange={() => setRadioValue('sewer_public_assessment_paid', true, scrollIntoView)}
                            disabled={disabled}
                          />

                          <Radio
                            id="sewer_public_assessment_paid_no"
                            name="sewer_public_assessment_paid"
                            label="No"
                            value={isFalse(data.sewer_public_assessment_paid)}
                            onChange={() => setRadioValue('sewer_public_assessment_paid', false, scrollIntoView)}
                            disabled={disabled}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <Notice
                  heading="Confirm that this property is connected with the municipality by contacting the sewer department or health department."
                  subHeading="It is important to have this accurately documented in MLS. Don't just rely on the seller's representation."
                />
              </div>
            ) : (
              <div className="mx-4 space-y-3 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
                <TextInput
                  label={
                    <div className="mb-1 text-base normal-case">
                      <div className="font-medium text-gray-700">
                        {questionNumber}a.  How many bedrooms is the system specifically designed to service?
                      </div>
                    </div>
                  }
                  name="sewer_private_bedrooms"
                  value={data.sewer_private_bedrooms}
                  onChange={(value) => setData({ ...data, sewer_private_bedrooms: value })}
                />

                <Notice
                  heading={
                    transaction.property_state === 'MA'
                      ? 'MA Title 5 & Number of Bedrooms listed in MLS: When listing a home that has a private sewer system, you are REQUIRED to list the home with the number of bedrooms approved for the private sewer/septic/cesspool as indicated specifically in section “D. System Information” of the Title 5 report.'
                      : 'Number of Bedrooms listed in MLS: When listing a home that has a private sewer system, you are REQUIRED to list the home with the number of bedrooms approved for the private sewer/septic/cesspool as indicated on public records.  You may be able to find the records HERE.  If not, check with the local health inspector.'
                  }
                  subHeading={
                    <span>
                      If you have any questions about this, please consult the Team Intranet Private Sewer page and Help & SUPPORT Forum for
                      more information and answers.
                      <br />
                      <br />
                      {transaction.property_state === 'MA'
                        ? 'Be sure that you DO NOT list more bedrooms in the MLS listing or Seller Disclosure than is represented in the Title V report and that the private sewer system is designed and approved for by the municipal Health Department or Board of Health office.'
                        : 'Be sure that you DO NOT list more bedrooms in the MLS listing or Seller Disclosure than is represented on public records and that the private sewer system is designed and approved for by the municipal Health Department or Board of Health office.'}
                    </span>
                  }
                />
              </div>
            ))}
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.fireplace ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Is there a fireplace, gas or propane fireplace, wood stove, pellet stove, or coal stove?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="fireplace_yes"
                name="fireplace"
                label="Yes"
                value={isTrue(data.fireplace)}
                onChange={() => setRadioValue('fireplace', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="fireplace_no"
                name="fireplace"
                label="No"
                value={isFalse(data.fireplace)}
                onChange={() => setRadioValue('fireplace', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.fireplace }}></div>}
          </div>

          {isTrue(data.fireplace) && (
            <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
              <TextInput
                label={
                  <div className="mb-1 text-base normal-case">
                    <div className="font-medium text-gray-700">{questionNumber}a.  What type(s) and how many?</div>
                  </div>
                }
                name="fireplace_info"
                value={data.fireplace_info}
                onChange={(value) => setData({ ...data, fireplace_info: value })}
              />
            </div>
          )}
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.special_amenities ? 'text-red-600' : 'text-gray-700')}>
              <span>
                {questionNumber}. Does the property have an alarm, central vacuum or other special amenities that should be disclosed in the
                listing?
              </span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="special_amenities_yes"
                name="special_amenities"
                label="Yes"
                value={isTrue(data.special_amenities)}
                onChange={() => setRadioValue('special_amenities', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="special_amenities_no"
                name="special_amenities"
                label="No"
                value={isFalse(data.special_amenities)}
                onChange={() => setRadioValue('special_amenities', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.special_amenities }}></div>}

            {isTrue(data.special_amenities) && (
              <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
                <TextArea
                  label={<span className="text-base font-medium normal-case text-gray-800">{questionNumber}a. Please describe below</span>}
                  name="special_amenities_description"
                  value={data.special_amenities_description}
                  onChange={(value) => setData({ ...data, special_amenities_description: value })}
                />
              </div>
            )}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <div className="mb-1 text-base normal-case">
              <div className="font-medium text-gray-700">{questionNumber}.  What Appliances are included in the sale?</div>
              <div>
                i.e. Range, wall oven, range hood, microwave, dishwasher, garbage disposal, clothes washer, clothes dryer, wine cooler, ice
                maker, upright or chest freezer, etc.
              </div>
            </div>
          }
          name="appliances"
          value={data.appliances}
          onChange={(value) => setData({ ...data, appliances: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <div className="mb-1 text-base normal-case">
              <div className="font-medium text-gray-700">
                {questionNumber}. What type of fuel(s) for the kitchen stove, wall oven, range and clothes dryer? Natural Gas, propane or
                electric?
              </div>
            </div>
          }
          name="fuel_used"
          value={data.fuel_used}
          onChange={(value) => setData({ ...data, fuel_used: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextInput
          label={
            <div className="mb-1 text-base normal-case">
              <div className="font-medium text-gray-700">
                {questionNumber}. Are the Smoke Detectors and Carbon Monoxide Detectors needing to be replaced prior to the municipal fire
                department certification?
              </div>
            </div>
          }
          name="detectors_status"
          value={data.detectors_status}
          onChange={(value) => setData({ ...data, detectors_status: value })}
        />
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.attic_water_damage ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Are there any visible signs of possible mold and water stains in the attic?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="attic_water_damage_yes"
                name="attic_water_damage"
                label="Yes"
                value={data.attic_water_damage === 'yes'}
                onChange={() => setRadioValue('attic_water_damage', 'yes' || true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="attic_water_damage_no"
                name="attic_water_damage"
                label="No"
                value={data.attic_water_damage === 'no'}
                onChange={() => setRadioValue('attic_water_damage', 'no' || false, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="attic_water_damage_not_accessible"
                name="attic_water_damage"
                label="Not Accessible During Visit"
                value={data.attic_water_damage === 'not accessible'}
                onChange={() => setRadioValue('attic_water_damage', 'not accessible', scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.attic_water_damage }}></div>}

            {data.attic_water_damage === 'yes' && (
              <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
                <TextArea
                  label={
                    <span className="text-base font-medium normal-case text-gray-800">
                      {questionNumber}a. Describe what you viewed and get a history from the seller.
                    </span>
                  }
                  name="attic_water_damage_description"
                  value={data.attic_water_damage_description}
                  onChange={(value) => setData({ ...data, attic_water_damage_description: value })}
                />
              </div>
            )}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.pets ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Are there pets living at the property?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="pets_yes"
                name="pets"
                label="Yes"
                value={isTrue(data.pets)}
                onChange={() => setRadioValue('pets', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="pets_no"
                name="pets"
                label="No"
                value={isFalse(data.pets)}
                onChange={() => setRadioValue('pets', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.pets }}></div>}

            {isTrue(data.pets) && (
              <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
                <TextInput
                  label={
                    <span className="text-base font-medium normal-case text-gray-800">
                      {questionNumber}a. How will the seller deal with pets during showings?
                    </span>
                  }
                  name="pets_showings"
                  value={data.pets_showings}
                  onChange={(value) => setData({ ...data, pets_showings: value })}
                />
              </div>
            )}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.extra_structures ? 'text-red-600' : 'text-gray-700')}>
              <span>{questionNumber}. Is there a garage, shed or other type of building or structure?</span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="extra_structures_yes"
                name="extra_structures"
                label="Yes"
                value={isTrue(data.extra_structures)}
                onChange={() => setRadioValue('extra_structures', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="extra_structures_no"
                name="extra_structures"
                label="No"
                value={isFalse(data.extra_structures)}
                onChange={() => setRadioValue('extra_structures', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.extra_structures }}></div>}

            {isTrue(data.extra_structures) && (
              <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
                <TextInput
                  label={
                    <span className="text-base font-medium normal-case text-gray-800">
                      {questionNumber}a. Please describe all additional garage or structures below.
                    </span>
                  }
                  name="extra_structures_description"
                  value={data.extra_structures_description}
                  onChange={(value) => setData({ ...data, extra_structures_description: value })}
                />
              </div>
            )}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <div className="space-y-3">
          <label className={classNames('font-medium', errors.video_surveillance ? 'text-red-600' : 'text-gray-700')}>
            <span>{questionNumber}. Are there any audio or video surveillance devices located at the property?</span>
          </label>

          <div className="flex space-x-4">
            <Radio
              id="video_surveillance_yes"
              name="video_surveillance"
              label="Yes"
              value={isTrue(data.video_surveillance)}
              onChange={() => setData({ ...data, video_surveillance: true })}
              disabled={disabled}
            />

            <Radio
              id="video_surveillance_no"
              name="video_surveillance"
              label="No"
              value={isFalse(data.video_surveillance)}
              onChange={() => setData({ ...data, video_surveillance: false })}
              disabled={disabled}
            />
          </div>

          {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.video_surveillance }}></div>}
        </div>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <div className="space-y-3">
          <label className={classNames('font-medium', errors.easements_assessments ? 'text-red-600' : 'text-gray-700')}>
            <span>{questionNumber}. Are there any known easements, or upcoming betterments or assessments?</span>
          </label>

          <div className="flex space-x-4">
            <Radio
              id="easements_assessments_yes"
              name="easements_assessments"
              label="Yes"
              value={isTrue(data.easements_assessments)}
              onChange={() => setData({ ...data, easements_assessments: true })}
              disabled={disabled}
            />

            <Radio
              id="easements_assessments_no"
              name="easements_assessments"
              label="No"
              value={data.easements_assessments !== undefined && isFalse(data.easements_assessments)}
              onChange={() => setData({ ...data, easements_assessments: false })}
              disabled={disabled}
            />
          </div>

          {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.easements_assessments }}></div>}
        </div>
      ),
    },
    {
      type: 'scrollable',
      id: 'name',
      render: (scrollIntoView, questionNumber) => (
        <Fragment>
          <div className="space-y-3">
            <label className={classNames('font-medium', errors.lsn_referrals ? 'text-red-600' : 'text-gray-700')}>
              <span>
                {questionNumber}. Does the seller need any referrals to LSN members including mover, move organizer, junk remover, real
                estate attorney or other services?
              </span>
            </label>

            <div className="flex space-x-4">
              <Radio
                id="lsn_referrals_yes"
                name="lsn_referrals"
                label="Yes"
                value={isTrue(data.lsn_referrals)}
                onChange={() => setRadioValue('lsn_referrals', true, scrollIntoView)}
                disabled={disabled}
              />

              <Radio
                id="lsn_referrals_no"
                name="lsn_referrals"
                label="No"
                value={isFalse(data.lsn_referrals)}
                onChange={() => setRadioValue('lsn_referrals', false, scrollIntoView)}
                disabled={disabled}
              />
            </div>

            {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.lsn_referrals }}></div>}

            {isTrue(data.lsn_referrals) && (
              <div className="mx-4 space-y-4 rounded-md border-2 border-dashed border-gray-400 bg-gray-100 p-4">
                <TextInput
                  label={<span className="text-base font-medium normal-case text-gray-800">{questionNumber}a. What services?</span>}
                  name="lsn_referrals_list"
                  value={data.lsn_referrals_list}
                  onChange={(value) => setData({ ...data, lsn_referrals_list: value })}
                />
              </div>
            )}
          </div>
        </Fragment>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <div className="space-y-3">
          <label className={classNames('font-medium', errors.irigation ? 'text-red-600' : 'text-gray-700')}>
            <span>{questionNumber}. Is there an irrigation (sprinker) system?</span>
          </label>

          <div className="flex space-x-4">
            <Radio
              id="irigation_yes"
              name="irigation"
              label="Yes"
              value={isTrue(data.irigation)}
              onChange={() => setData({ ...data, irigation: true })}
              disabled={disabled}
            />

            <Radio
              id="irigation_no"
              name="irigation"
              label="No"
              value={isFalse(data.irigation)}
              onChange={() => setData({ ...data, irigation: false })}
              disabled={disabled}
            />
          </div>

          {errors && <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.irigation }}></div>}
        </div>
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextArea
          label={
            <div className="mb-1 text-base normal-case">
              <div className="font-medium text-gray-700">
                {questionNumber}. What updates has the seller done to the property while owning it?
              </div>
            </div>
          }
          name="updates"
          value={data.updates}
          onChange={(value) => setData({ ...data, updates: value })}
        />
      ),
    },
    {
      type: 'section',
      render: (questionNumber) => (
        <TextArea
          label={
            <div className="mb-1 text-base normal-case">
              <div className="font-medium text-gray-700">
                {questionNumber}. What visible work needs do you suggest that the seller do to prepare the property for sale?
              </div>
            </div>
          }
          name="sale_preparation_suggestions"
          value={data.sale_preparation_suggestions}
          onChange={(value) => setData({ ...data, sale_preparation_suggestions: value })}
        />
      ),
    },
  ]

  const isTrue = (value) => {
    if (value == undefined) {
      return false
    }

    return value === true || value === 1 || value === '1'
  }

  const isFalse = (value) => {
    if (value == undefined) {
      return false
    }

    return value === false || value === 0 || value === '0'
  }

  return (
    <Dialog
      ref={scrollRef}
      cancelText="Close"
      focusRef={focusRef}
      footerActions={
        transaction.editable && (
          <Button form="transactionForm" type="submit" theme="solid">
            Save Changes
          </Button>
        )
      }
      position="top"
      show={open}
      size={'max-w-4xl'}
      title={data.name}
      onClosed={onClosed}
    >
      {data && (
        <form id="transactionForm" className="space-y-4" onSubmit={onSubmit}>
          {formSections.map((item, index) => {
            if (item.type === 'scrollable') {
              return (
                <ScrollableItem key={index} id={`question-${index}`} onScroll={(ref) => handleScroll(ref)}>
                  {({ scrollIntoView }) => item.render(scrollIntoView, index + 1)}
                </ScrollableItem>
              )
            } else if (item.type === 'section') {
              return (
                <div key={index} id={`question-${index}`}>
                  {item.render(index + 1)}
                </div>
              )
            }
            return null
          })}

          <div>
            <TextArea
              label={
                <div className="mb-1 text-base normal-case">
                  <div className="font-medium text-gray-700">Additional Field Notes</div>
                </div>
              }
              name="field_notes"
              value={data.field_notes}
              onChange={(value) => setData({ ...data, field_notes: value })}
            />
          </div>
        </form>
      )}
    </Dialog>
  )
}

export default forwardRef(SellerVisitQuestionnaire)
