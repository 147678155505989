import { Fragment, useRef } from 'react'
import { router, useForm } from '@inertiajs/react'
import ClosingWorksheet from './ClosingReport/Index'
import LandlordVisitQuestionnaire from './LandlordVisitQuestionnaire'
import SellerVisitQuestionnaire from './SellerVisitQuestionnaire'
import Helpers from '@/utils/helpers'

export default function FormDialog({ open, record, disabled, onClosed }) {
  const scrollRef = useRef()

  const { data, setData, clearErrors, errors, setError } = useForm({ ...record })

  const handleScroll = (ref) => {
    setTimeout(() => {
      let container = scrollRef.current
      let scrollable = ref.current
      let scrollableOffsetTop = scrollable.offsetTop - 105

      if (scrollableOffsetTop < container.scrollTop || scrollableOffsetTop > container.clientHeight)
        container.scrollTo({
          behavior: 'smooth',
          top: scrollableOffsetTop,
        })
    }, 300)
  }

  const setRadioValue = (field, value, scrollIntoView) => {
    scrollIntoView()
    setData({ ...data, [field]: value })
  }

  const submit = (event) => {
    event.preventDefault()

    let transformedData = { ...data }
    transformedData.reimbursements =
      transformedData.reimbursements?.map((r) => ({
        id: r.id,
        type: r.type,
        description: r.description,
        amount: r.amount,
        receipt: r.receipt_file_id == undefined && r.receipt != undefined ? r.receipt : null,
        receipt_file_id: r.receipt_file_id || null,
        distributions: r.distributions.map((d) => ({ id: d.id, associate_id: d.associate_id, amount: d.amount })),
      })) || []

    router.post(route('transactions.forms.update', data.id), Helpers.prepareFormData({ _method: 'put', ...transformedData }), {
      only: ['errors', 'flash', 'history', 'transaction'],
      onSuccess: () => {
        clearErrors()
        onClosed()
      },
      onError: (errors) => {
        setError(errors)
      },
    })
  }

  return (
    <Fragment>
      {record.form_id == 1 && (
        <SellerVisitQuestionnaire
          ref={scrollRef}
          data={data}
          errors={errors}
          open={open}
          disabled={disabled}
          handleScroll={handleScroll}
          setData={setData}
          setRadioValue={setRadioValue}
          onClosed={onClosed}
          onSubmit={submit}
        />
      )}

      {record.form_id == 2 && (
        <ClosingWorksheet
          ref={scrollRef}
          clearErrors={clearErrors}
          data={data}
          errors={errors}
          open={open}
          setData={setData}
          setError={setError}
          onClosed={onClosed}
          onSubmit={submit}
        />
      )}

      {record.form_id == 3 && (
        <LandlordVisitQuestionnaire
          ref={scrollRef}
          data={data}
          errors={errors}
          open={open}
          disabled={disabled}
          handleScroll={handleScroll}
          setData={setData}
          setRadioValue={setRadioValue}
          onClosed={onClosed}
          onSubmit={submit}
        />
      )}
    </Fragment>
  )
}
